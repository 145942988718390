import React, { CSSProperties } from 'react';
import { GenericTwoPopupConfig } from '@roc-digital/types/popups';
import { checkFrequency, createResolverChain, waitForDelay, waitForScrollDepth, waitForUrlMatch, waitForUserState } from '../logic/popup-conditions';
import { PopupProps } from "@roc-digital/types";
import { Button, ButtonSizes, ButtonVariants } from './Button';
import { RoundCloseButton } from './RoundCloseButton';

export function GenericTwoPopup(props: PopupProps<GenericTwoPopupConfig>) {

  const config = props.popup.config || {};
  const title = config.title;
  const body = config.body;
  const [visible, setVisible] = React.useState(false);

  React.useEffect(() => {

    if (props.isPreview) return;

    const config = props.popup.config;
    const trigger = config?.trigger || {};
    const resolver = createResolverChain();

    if(trigger.userState && trigger.userState !== 'any') {
      resolver.chain(waitForUserState(trigger.userState))
    }

    if (trigger.urlMatch) {
      resolver.chain(waitForUrlMatch(trigger.urlMatch))
    }

    if (trigger.scrollDepth) {
      resolver.chain(waitForScrollDepth(trigger.scrollDepth))
    }

    if (trigger.delay) {
      resolver.chain(waitForDelay(trigger.delay));
    }

    if (resolver.resolvers.length === 0) {
      resolver.chain(waitForDelay(2));
    }

    resolver.chain(checkFrequency(props.popup.id.toString(), 1));

    resolver.onChange = (isReady) => {
      if (isReady) {
        setVisible(true);
      }
    }

    resolver.start();

    return () => {
      resolver.reset();
    }

  }, []);

  if (!visible && !props.isPreview) return <></>;

  return <Backdrop isPreview={props.isPreview} config={config}>
    <PopupBox config={config}>
      <Title config={config}>{title}</Title>
      <Body config={config}>{body}</Body>
      <ActionButton config={config} isPreview={props.isPreview}/>
      <RoundCloseButton onClick={() => setVisible(false)}/>
      <CloseLink onClick={() => setVisible(false)} />
    </PopupBox>
  </Backdrop>;
}

export interface CloseLinkProps {
  onClick?: () => void;
}

function CloseLink (props: CloseLinkProps) {
  return <span className='relative w-full'>
  <div className='absolute w-full left-0 right-0 -top-2 text-black text-sm flex place-content-center'>
    <div className='p-1 bg-[#ffffff3d] rounded-full w-[50px] cursor-pointer' onClick={props.onClick}>close</div>
  </div>
</span>;
}

function Title(props: { config: GenericTwoPopupConfig, children: any }) {
  return <div style={{
    fontFamily: 'Chivo, sans-serif',
    fontWeight: '700',
    textAlign: 'center',
    fontSize: '24px',
    color: 'black',
    letterSpacing: '1.44px',
    lineHeight: '150%',
  }}>
    {props.children}
  </div>
}

function Body(props: { config: GenericTwoPopupConfig, children: any }) {
  
  return <div style={{
    fontFamily: 'ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    fontWeight: '500',
    fontSize: '16px',
    textAlign: props.config.size === 'large' ? 'center' : 'left',
    color: 'black',
    lineHeight: '28px',
    display: 'flex',
    flexDirection: props.config.size === 'large' ? 'column' : 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '18px'
  }}>
    {/**
    <img 
      src={props.config.imageUrl} 
      className={`max-width-auto ${props.config.size === 'large' ? 'large-popup-image' : 'medium-popup-image'}`}
    /> 
    */}
    <Media url={props.config.imageUrl} size={props.config.size}/>
    {props.children}
  </div>;
}

function Media(url: any, size: any) {
  const imageMatch = /\.(jpg|jpeg|png|gif|webp|svg|bmp|tiff|tif)$/i;
  console.log('URL in Media', url.url);
  if (imageMatch.test(url.url)) {
    return     <img 
    src={url.url} 
    className={`max-width-auto ${size === 'large' ? 'large-popup-image' : 'medium-popup-image'}`}
    />
  }
  const isYouTubeOrRumble = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be|rumble\.com\/(embed\/)?v[0-9a-zA-Z]+)/i;
  if (isYouTubeOrRumble.test(url.url)) {
    return VideoEmbed(url);
  } else {
    console.log('Video not detected')
  }
  
  console.log('No valid image or video in popup');
  return null;
}

const getVideoEmbedUrl = (url) => {
  const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/i;
  const rumbleRegex = /^(https?:\/\/)?(www\.)?rumble\.com\/(embed\/)?v[0-9a-zA-Z]+/i;
  console.log('URL in embed', url);
  if (youtubeRegex.test(url)) {
    // Extract YouTube video ID and construct embed URL
    const videoId = url.split('v=')[1]?.split('&')[0] || url.split('/').pop();
    return `https://www.youtube.com/embed/${videoId}?autoplay=1`;
  } else if (rumbleRegex.test(url)) {
    // Extract Rumble video ID and construct embed URL
    const videoId = url.split('/')[4]?.split('-')[0];
    return `https://rumble.com/embed/${videoId}?autoplay=2`;
  }

  return null;
};

const VideoEmbed = ({ url }) => {
  const embedUrl = getVideoEmbedUrl(url);

  if (!embedUrl) {
    return <p>Invalid YouTube or Rumble URL</p>;
  }

  return (
    <div className="video-responsive">
      <iframe
        width="100%"
        height="100%"
        src={embedUrl}
        title="Video Player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};

function PopupBox(props: { config: GenericTwoPopupConfig, children: any }) {
  const position = props.config?.position;

  const style: CSSProperties = {
    position: 'relative',
    backgroundColor: 'white',
    bottom: position === 'center' ? '24px' : '0px',
    top: position === 'center' ? '0px' : 'unset',
    left: '0px',
    right: '0px',
    display: 'flex',
    flexDirection: 'column',
    width: props.config.size === 'large' ? '1042px' : '950px',
    maxWidth: '100%',
    textAlign: 'center',
    gap: '18px',
    padding: '24px 47px',
  }

  return <div style={style} className='max-width-auto'>
    {props.children}
  </div>
}


function Backdrop(props: { isPreview?: boolean, config: GenericTwoPopupConfig, children: any }) {
  return <div style={{
    position: props.isPreview ? 'absolute' : 'fixed',
    backgroundColor: 'rgba(0,0,0,.5)',
    width: props.isPreview ? '100%' : '100vw',
    height: props.isPreview ? '100%' : '100vh',
    top: '0px',
    left: '0px',
    right: '0px',
    zIndex: 1000,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignContent: 'center',
    padding: '24px',
    justifyContent: props.config?.position === 'bottom' ? 'flex-end' : 'center',
  }}>{props.children}</div>
}

function ActionButton(props: { isPreview?: boolean, config: GenericTwoPopupConfig}) {
  const actionText = props.config?.actionText;
  const actionUrl = props.config?.actionUrl;

  return <a href={props.isPreview ? "javascript:void(0)" : actionUrl} style={{ textAlign: 'center' }}>
    <Button
      size={ButtonSizes.medium}
      variant={ButtonVariants.primary}
      title={actionText}
    ></Button>
  </a>
}