import { useNavigate } from 'react-router';
import { Button, ButtonSizes, Heading1, Heading2 } from '@roc-digital/ui-web';

interface Props {
  size?: string;
  message?: string;
}

const SubscribeBanner = ({ size = 'small', message }: Props) => {
  const navigate = useNavigate();

  return (
    <>
      {size === 'small' &&
        <div className={'flex w-full py-10 mb-6 bg-[#24AAE1] items-center justify-between'}>
          <span className={'flex items-center w-full justify-between max-w-5xl mx-auto px-2'}>
            <span className={'flex items-center w-1/2'}>
              <Heading1 className={'text-white !font-chivo !font-extrabold !text-[48px] !leading-10 sm:!text-[26px] md:!text-[36px] mr-2'}>
                {message || 'Subscribe to MxM News+ and access our Exclusive content.'}
              </Heading1>
            </span>
            <span className={'flex items-center w-1/2 justify-end'}>
              <Button
                title={'Subscribe'}
                id={'subscribe'}
                size={ButtonSizes.medium}
                customClassName={'bg-[#24AAE1] border-2 border-white mr-4'}
                customTextClassName={'text-white !text-sm'}
                clickEvent={() => navigate('/account/billing')}
              />
            </span>
          </span>
        </div>
      }
      {size === 'large' &&
        <div className={'flex w-full py-6 px-1 mb-6 bg-[#24AAE1] items-center justify-between'}>
          <span className={'flex items-center flex-col w-full text-center max-w-2xl mx-auto mt-6'}>
            <Heading1 className={'text-white !font-chivo !font-extrabold text-[64px] leading-[64px] sm:text-[48px]'}>
              Mainstream News without the Mainstream Bias
            </Heading1>
            <Heading2 className={'text-white !font-chivo !font-bold text-md'}>
              Cut through the censorship, mainstream bias, and institutional dominance that has left
              society divided. Access our exclusive content. Subscribe to MxM News+ now!
            </Heading2>
            <span className={'flex items-center w-full justify-center my-12'}>
              <Button
                title={'Subscribe'}
                id={'subscribe'}
                size={ButtonSizes.medium}
                customClassName={'bg-[#24AAE1] border-2 border-white mr-4'}
                customTextClassName={'text-white !text-sm'}
                clickEvent={() => navigate('/account/billing')}
              />
            </span>
          </span>
        </div>
      }
    </>
  );
};

SubscribeBanner.displayName = 'SubscribeBanner';

export default SubscribeBanner;
