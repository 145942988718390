import React, { useState } from 'react';
import { Button, ButtonVariants } from '@roc-digital/ui-web';
import { UserLocationForm } from '@/components/elements';
import Interests from '@/components/elements/Interests';
import Following from '@/components/elements/Following';
import { Navigate } from 'react-router';
import Subscription from '@/components/elements/Subscription';
import CollectPhoneNumber from '@/components/elements/CollectPhoneNumber';
import { useLocation } from 'react-router-dom';
import { updateUser } from '@roc-digital/mxm-base/logic';
import { SessionState } from '@roc-digital/mxm-base/state';

export interface UserOnboardingFlowProps { }

// const steps = ['location', 'interests',  'people', 'payment', 'finish'];
const steps = ['phone', 'location', 'interests', 'payment', 'finish'];

export function UserOnboardingFlow(props: UserOnboardingFlowProps) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const phone = queryParams.get('phone');
  const [phoneNumber, setPhoneNumber] = useState('');

  const savePhone = () =>{
    updateUser(SessionState, {phone: phone || null})
    .catch(e => console.error(e));
  }

  const [step, setStep] = React.useState(steps[0]);
  const [confirmSMS, setConfirmSMS] = useState(false);

  const currentIndex = steps.indexOf(step);
  const beforeFinish = currentIndex === steps.length - 2;

  const goNext = () => {
    setStep((step) => {
      const currentIndex = steps.indexOf(step);
      return steps[currentIndex+1];
    })
  };

  const goBack = () => {
    setStep((step) => {
      const currentIndex = steps.indexOf(step);
      return steps[currentIndex-1];
    })
  }
  const hasPrev = currentIndex > 0;
  const hasNext = currentIndex < steps.length;
  let nextText = '';

  if(beforeFinish) {
    nextText = 'Complete'
  }

  if(step === 'finish') {
    return <Navigate to='/trending'/>
  }

  const agreeToSMS = (value: any) => {
    // child component passes value of checkbox
    setConfirmSMS(value);
  };

  if (phone && phone !== '' && phoneNumber === '') { 
    setPhoneNumber(phone);
    savePhone();
    goNext();
  }

  return <StepView
  hideBackButton={!hasPrev}
  canProgress={hasNext && confirmSMS}
  onNext={goNext}
  onBack={goBack}
  nextText={nextText}
  skip={step==='phone' ? true : false} 
>
  {step === 'location' ? <UserLocationForm/> : null}
  {step === 'interests' ? <Interests/> : null}
  {step === 'people' ? <Following/> : null}
  {step === 'payment' ? <Subscription/> : null}
  {step === 'phone' ? <CollectPhoneNumber confirmSMS={agreeToSMS} startingSMSValue={confirmSMS}/> : null}
</StepView>;
}

interface StepViewProps {
  header?: any;
  children?: any;
  nextText?: string;
  backText?: string;
  onBack?: () => void;
  onNext?: () => void;
  canProgress?: boolean;
  hideBackButton?: boolean;
  skip?: boolean;
}

function StepView(props: StepViewProps) {
  return  <div className="flex flex-col items-center rounded-2xl relative flex-1 grow">
    <div className="flex flex-col w-80 gap-8 flex-1 grow">
      <div className="font-chivo text-4xl font-bold dark:text-white">Customize your experience</div>
      <div className='flex flex-col max-h-[520px] flex-1 grow'>
        {props.children}
      </div>
      <div className="flex items-center justify-between">
        {!props.hideBackButton ? <>
          <Button variant={ButtonVariants.plain} customClassName="!w-28" clickEvent={props.onBack}>
            <span className="dark:text-white">{props.backText || 'Back'}</span>
          </Button>
        </> : null}
        <div className="grow"></div>
        {
          props.skip ? <Button variant={ButtonVariants.plain} customClassName="!w-28 mr-4" clickEvent={() => props.onNext?.()}>Skip</Button> : null
        }
        <Button
          customClassName="!w-28"
          disabled={!props.canProgress }
          clickEvent={() => props.canProgress && props.onNext?.()}>
            {props.nextText || `Next >`}
          </Button>
      </div>
    </div>
  </div>
}